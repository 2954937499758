<template>
  <div class="tips-game">
    <div v-if="bet.isVisible === false" class="tips-game__disabled" />
    <div class="tips-game__league">
      <span class="name">{{ bet.tournamentName || bet.tournament_name }}</span> |
      <span class="round">{{ roundName }}</span>
    </div>
    <div class="tips-game__info">
      <div class="tips-game__info__teams">
        <div class="tips-game__info__teams__team" :class="{ 'is-selected': selectedResult === 1 }">
          <div class="logo">
            <img :src="`https://az755631.vo.msecnd.net/teams-80/${bet.home_team_logo}`" alt="" />
          </div>
          <div class="name">
            {{ bet.home_team_acronym }}
          </div>
        </div>
        <div class="tips-game__info__teams__vs" :class="{ 'is-selected': selectedResult === 3 }">
          VS
        </div>
        <div class="tips-game__info__teams__team" :class="{ 'is-selected': selectedResult === 2 }">
          <div class="name">
            {{ bet.away_team_acronym }}
          </div>
          <div class="logo">
            <img :src="`https://az755631.vo.msecnd.net/teams-80/${bet.away_team_logo}`" alt="" />
          </div>
        </div>
      </div>
      <div class="tips-game__info__result">
        <div class="tips-game__info__result__name">
          <div>
            {{ betText }}
          </div>
        </div>
        <div class="tips-game__info__result__amount">
          <div>$ {{ bet.investment.toFixed(2) }} <span class="currency">MXN</span></div>
        </div>
      </div>
    </div>
    <TipsSwitchSimulator v-model="bet.isVisible" is-mobile />
  </div>
</template>

<script>
const seasonType1Abbreviations = {
  PO1: 'CFI',
  PO2: 'CFV',
  PO3: 'SFI',
  PO4: 'SFV',
  PO5: 'FI',
  PO6: 'FV',
};

export default {
  name: 'TipsGameMobile',
  components: {
    TipsSwitchSimulator: () => import('@/components/Sections/GolTipster-v2/Tips/TipsSwitchSimulator'),
  },
  props: {
    bet: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isDisabled: false,
    };
  },
  computed: {
    selectedResult() {
      if (this.bet.bet_subtype_id === 2) {
        return 3;
      }
      return this.bet.bet_suggested.home_team_logo ? 1 : 2;
    },
    roundName() {
      const abbreviation = seasonType1Abbreviations[this.bet.matchday_abbreviation];
      return abbreviation || this.bet.matchday_abbreviation.replace('M', 'Jornada ');
    },
    betText() {
      let text = '';
      if (this.bet.bet_subtype_id) {
        switch (this.bet.bet_subtype_id) {
          case 1:
            text = 'Gana';
            break;
          case 2:
            text = 'Empate';
            break;
          case 3:
            text = 'Gana / Empata';
            break;
          case 4:
            text = 'Gana / Gana';
            break;
          default:
            text = '';
        }
      } else {
        text =
          this.bet.bet_suggested.suggestion === 'G/E'
            ? 'Gana / Empata'
            : this.bet.bet_suggested.suggestion === 'G/G'
            ? 'Gana / Gana'
            : this.bet.bet_suggested.suggestion;
      }
      return text;
    },
  },
  methods: {
    onSwitchInput($event) {
      this.isDisabled = $event;
      this.bet.isDisabled = $event;
    },
  },
  mounted() {
    this.isDisabled = this.bet.isDisabled;
  },
};
</script>

<style scoped lang="scss">
.tips-game {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 0.75em;
  border: solid 1px #000;
  font-size: 1rem;
  padding: 0.5em 0.3125em;
  position: relative;

  &__disabled {
    position: absolute;
    top: 0;
    width: 100%;
    height: 70%;
    background-color: white;
    border-radius: 0.75em;
    opacity: 0.5;
  }

  &__league {
    border-radius: 6px;
    background-color: #132839;
    width: 100%;
    font-size: 0.75em;
    font-family: Roboto-Medium, sans-serif;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    color: #fff;
    padding: 0.5em 0;
    font-weight: 500;

    & > span {
      &:first-child {
        margin-right: 0.5em;
      }
      &:last-child {
        margin-left: 0.5em;
      }
    }
  }

  &__info {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0.5em 0 0.75em;
    column-gap: 0.4rem;

    &__teams {
      display: grid;
      grid-template-columns: 1fr 1.8em 1fr;

      &__team {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 0.2em 0.4em;
        justify-self: center;

        & .name {
          height: 0.75em;
          padding-top: 0.2em;
          font-family: Roboto-Medium, sans-serif;
          font-size: 0.75em;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.83;
          letter-spacing: normal;
          text-align: left;
          color: black;
        }

        & .logo {
          width: 1.7em;
          height: 1.7em;
          margin: 0 0.25em;
        }
      }

      &__vs {
        font-family: Roboto-Medium, sans-serif;
        font-size: 0.7em;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.62;
        letter-spacing: normal;
        color: black;
        align-self: center;
        padding: 0.5rem 0.4rem 0.2rem;
      }
    }

    &__result {
      display: grid;
      grid-template-columns: min-content 1fr;

      &__name {
        width: 7.6em;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        background-color: #cbee6b;
        font-family: Roboto-Black, sans-serif;
        font-size: 0.625em;
        white-space: nowrap;
        color: black;
        padding: 0 0.5em;
      }

      &__amount {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 0.6em;
        font-family: Roboto-Bold, sans-serif;
        font-size: 0.8125em;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.37;
        letter-spacing: normal;
        color: black;
        white-space: nowrap;

        & span.currency {
          font-size: 0.65em;
        }
      }
    }

    @media screen and (max-width: 388px) {
      grid-template-columns: 1fr;

      &__teams {
        font-size: 1.2em;
      }

      &__result {
        margin-top: 0.8em;
        justify-self: center;

        &__name {
          padding: 0.95em 0;
        }
      }
    }
  }
}

.is-selected {
  border-radius: 5px;
  background-color: #0f92ff;
  color: white !important;

  & > div.name {
    color: white !important;
  }
}
</style>
